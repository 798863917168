import Difficulty from "../../configs/difficulty"
import BaseEnemy from "../enemies/base-enemy"
import MediumEnemy from "../enemies/medium-enemy"
import KamikazeEnemy from "../enemies/kamikaze-enemy"
import LightEnemy from "../enemies/light-enemy"
import HeavyEnemy from "../enemies/heavy-enemy"
import Player from "../player"

export default class EnemiesController extends Phaser.GameObjects.Group {
    scene: Phaser.Scene
    player: Player


    constructor(scene: Phaser.Scene) {
        super(scene)
        this.scene = scene

        this.player = scene.children.getByName("player") as Player

        for (let i = 0; i < 50; i++) {
            const heavy = new MediumEnemy(this.scene)
            heavy.death(true)
            this.add(heavy)

            const kamikaze = new KamikazeEnemy(this.scene, this.player)
            kamikaze.death(true)
            this.add(kamikaze)

            const light = new LightEnemy(this.scene)
            light.death(true)
            this.add(light)

            const rock = new MediumEnemy(this.scene)
            rock.death(true)
            this.add(rock)
        }
    }

    spawn(): BaseEnemy {
        let randomType;
        let enemy!: BaseEnemy
        const types = ["heavy", "kamikaze", "light", "medium"]

        if (Difficulty.DIFFICULTY_STAGE < 4) {
            randomType = Math.random() <= 0.75 ? types[Difficulty.DIFFICULTY_STAGE] : types[2];
        }
        else {
            randomType = Phaser.Math.RND.pick(types);

        }

        // can we reuse enemy
        // if not spawn a new one
        const enemyArray = this.getMatching('enemyType', randomType)
        for (let i = 0; i < enemyArray.length; i++) {
            const element = enemyArray[i];

            if (!element.active) {
                enemy = element
                break;
            }
        }


        if (enemy !== undefined && enemy !== null) {
            enemy.alive()
            return enemy
        }

        // spawn new one
        switch (randomType) {
            case "kamikaze":
                enemy = new KamikazeEnemy(this.scene, this.player)
                break
            case "light":
                enemy = new LightEnemy(this.scene)
                break
            case "medium":
                enemy = new MediumEnemy(this.scene)
                break
            case "heavy":
                enemy = new HeavyEnemy(this.scene)
                break
            default:
                console.log(`unexpected type ${randomType}`)
                break;
        }

        this.add(enemy)
        return enemy as BaseEnemy
    }
}