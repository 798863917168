import { parseHexColor } from "@/game/shared/utils/Helpers";
import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import CircularProgress from "phaser3-rex-plugins/plugins/circularprogress";
import Scaling from "../configs/scaling";
import GameHelper from "../util/game-helper";
import Background from "./background";

enum LoadStates {
    LOADING = 'loading',
    FINISH_STORY = 'story',
    FINISH_LOAD = 'load'
  }

export default class Load extends Phaser.Scene {
    loadProgressRequired: LoadStates[] = [LoadStates.FINISH_LOAD];
    loadProgress: LoadStates[] = [];

    constructor() {
        super({
            key: "load"
        });
    }

    preload() {
        //Add logo
        const logo = this.add.image(this.cameras.main.width / 2, (this.cameras.main.height / 2) - (Scaling.getPixelbyDPR(60)), "logo");

        /** Progression */
        const loader = new CircularProgress(this, {
            x: this.cameras.main.centerX, y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
            radius: Scaling.getPixelbyDPR(25),
            trackColor: 0x000000,
            barColor: parseHexColor(Setup.getValue('settings.game.load.color', 0xFFFFFF)).color,
            thickness: 0.1,
            valuechangeCallback: () => { }
        });
        this.add.existing(loader);

        //Loading event
        this.load.on("progress", (value: number) => {
            loader.setValue(value);
        });


        //Completion event
        this.load.on("complete", () => {
            loader.setAlpha(0);
      
            this.setupAnimations()
            this.finish(LoadStates.FINISH_LOAD);
            this.tweens.add({
              targets: logo,
              alpha: 0,
              duration: 500,
              ease: 'ease'
            })
      
            const backgroundScene = this.scene.get("background") as Background;
            backgroundScene.playAudio();
          });

        //Load images
        this.load.image("background-game", Scaling.ImagePath("background_game", "png"));
        this.load.image("background-waves-left", Scaling.ImagePath("waves_left", "png"));
        this.load.image("background-waves-right", Scaling.ImagePath("waves_right", "png"));
        this.load.image("background-points", Scaling.ImagePath("bg_points", "png"));
        this.load.image("background-lifes", Scaling.ImagePath("bg_lifes", "png"));
        this.load.image("health-empty", Scaling.ImagePath("icon_life_empty", "png"));
        this.load.image("upgrade-life", Scaling.ImagePath("upgrade_life", "png"));
        this.load.image("upgrade-shield", Scaling.ImagePath("upgrade_shield", "png"));
        this.load.image("upgrade-fire", Scaling.ImagePath("upgrade_fire", "png"));
        this.load.image("bullet-player", Scaling.ImagePath("sprite_ammunition", "png"));
        this.load.image("icon_lives", Scaling.ImagePath("icon-lifes", "png"));
        this.load.image("icon_score", Scaling.ImagePath("icon-points", "png"));

        //Load atlas
        this.load.atlas('enemy', Scaling.ImagePath("enemy_sprites", "png"), Scaling.ImagePath("enemy_spritesAtlas", "json"));
        this.load.atlas("player", Scaling.ImagePath("sprite_player", "png"), Scaling.ImagePath("sprite_player_atlas", "json"))

        //load spritesheet
        this.load.spritesheet("explosion", Scaling.ImagePath("sprite_hit", "png"), {
            frameWidth: Scaling.getPixelbyDPR(64),
            frameHeight: Scaling.getPixelbyDPR(64)
        });

        //Load sounds
        this.load.audio("theme", Setup.getSound("music_action.mp3"));
        this.load.audio("laser-player", Setup.getSound("laser_player.mp3"));
        this.load.audio("laser-mini", Setup.getSound("laser_mini.mp3"));
        this.load.audio("effect-death", Setup.getSound("effect_death.mp3"));
        this.load.audio("effect-hit", Setup.getSound("effect_hit.mp3"));
        this.load.audio("effect-powerup", Setup.getSound("effect_powerup.mp3"));
        this.load.audio("effect-powerup-drop", Setup.getSound("effect_powerup_drop.mp3"));
    }

    setupAnimations() {

        this.anims.create({
            key: "run-explosion",
            frames: this.anims.generateFrameNumbers("explosion", { start: 0, end: 5 }),
            frameRate: 12
        });
    }

    finish(state: LoadStates) {
        this.loadProgress.push(state);
    
        const isCompleted = this.loadProgressRequired.every(progress => this.loadProgress.includes(progress));
        if (isCompleted) {
          this.time.delayedCall(2500, () => this.game.events.emit("loaded"));
        }
      }  
}


