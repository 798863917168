import Phaser from 'phaser'
import Constants from '../../configs/constants'
import Difficulty from '../../configs/difficulty'
import Scaling from '../../configs/scaling'
import GameHelper from '../../util/game-helper'
import Player from '../player'
import BaseEnemy from './base-enemy'

enum KAMIKAZE_STATES {
  IDLE = "idle",
  KAMIKAZE = "kamikaze"
}

export default class KamikazeEnemy extends BaseEnemy {
  player: Player;
  incrementX: number;
  playerIsLeft: boolean;
  tweenKamikaze!: Phaser.Tweens.Tween;

  constructor(scene: Phaser.Scene, player: Player) {
    super(scene, 'enemy-kamikaze-1-1')
    this.player = player

    //Set data
    this.enemyType = 'kamikaze'
    this.maxHealth = this.health = Constants.HEALTH_ENEMY_KAMIKAZE
    this.points = Constants.SCORE_ENEMY_KAMIKAZE
    this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER + this.speedAccelerate
    this.incrementX = Scaling.getPixelbyDPR(2)
    this.playerIsLeft = false

    // state manager
    this.setState(KAMIKAZE_STATES.IDLE);
    
    //Set initial frame
    this.updateState()
  }

  update() {
    if (!this.active) return

    //Super stuff
    super.update()

    switch (this.state) {

      case KAMIKAZE_STATES.IDLE:
        if (this.y > this.scene.cameras.main.displayHeight / 4) {
          if (this.health > 1) {
            this.hit(true);
          }
          this.startKamikaze();
        }
        break;

      case KAMIKAZE_STATES.KAMIKAZE:
        // rotate object
        this.rotation += Math.PI * 0.1
        break;
    }
  }

  startKamikaze() {
    this.setState(KAMIKAZE_STATES.KAMIKAZE);

    // setup for curve
    const startPoint = new Phaser.Math.Vector2(this.x, this.y)
    const controlPoint = new Phaser.Math.Vector2(this.x, this.player.y + 50)
    const endPoint = new Phaser.Math.Vector2(this.player.x, this.player.y)
    const curve = new Phaser.Curves.QuadraticBezier(startPoint, controlPoint, endPoint)

    this.tweenKamikaze = this.scene.tweens.addCounter({
      from: 0,
      to: 1,
      duration: 2000,
      ease: Phaser.Math.Easing.Sine.InOut,
      onUpdate: (tween) => {
        const current = tween.data[0].current;

        if (current) {
          const position = curve.getPoint(current);
          this.setPosition(position.x, position.y);
        }
      },
      onComplete: () => {
        this.death(false, false);
      }
    })
  }

  randomXPosition(): number {
    if (this.player !== null) {
      this.player = this.scene.children.getByName('player') as Player
    }

    const halfWidth =  (this.scene.player.displayWidth > this.displayWidth ? this.scene.player.displayWidth : this.displayWidth) / 2;
    return this.player.x < this.scene.sys.canvas.width / 2 ? this.scene.cameras.main.width - halfWidth : halfWidth;
  }

  death(silent: boolean, receivePoints = true){
    super.death(silent, receivePoints);

    this.setState(KAMIKAZE_STATES.IDLE);
    if(this.tweenKamikaze){
      this.tweenKamikaze.stop();
    }
  }
}