export default class Difficulty {

    //Defaults
    static DEFAULT_SCORE_THRESHOLD = 2;
    static DEFAULT_POWERUP_SCORE_THRESHOLD = 2;
    static DEFAULT_SPEED_MULTIPLIER = 1;
    static DEFAULT_SPAWN_MULTIPLIER = 1;

    //Determines after how many points difficulty increases
    static SCORE_THRESHOLD = 2;
    static SCORE_THRESHOLD_MAX = 120;
    static SCORE_THRESHOLD_INCREMENT = 5;

    //Powerup drops
    static POWERUP_SCORE_THRESHOLD = 2;
    static POWERUP_SCORE_MAX = 80;
    static POWERUP_SCORE_INCREMENT = 5;

    //Movement speed increase (for lasers, enemies, powerups)
    static SPEED_MULTIPLIER = 1.5;
    static SPEED_MULTIPLIER_MAX = 3.5;
    static SPEED_MULTIPLIER_INCREMENT = 0.1;

    //Spawn rate increase (for enemies)
    static SPAWN_MULTIPLIER = 1;
    static SPAWN_MULTIPLIER_MAX = 6;
    static SPAWN_MULTIPLIER_INCREMENT = 0.5;

    //Misc.
    static DIFFICULTY_STAGE = 1;
    static DIFFICULTY_DELAY = 4;

    static incrementDifficult() {
        this.DIFFICULTY_STAGE++;

        //Block any increments, when stage is under certain level
        if(this.DIFFICULTY_STAGE < this.DIFFICULTY_DELAY)
            return;

        //Increase score threshold
        this.SCORE_THRESHOLD += this.SCORE_THRESHOLD_INCREMENT;
        if(this.SCORE_THRESHOLD > this.SCORE_THRESHOLD_MAX) {
            this.SCORE_THRESHOLD = this.SCORE_THRESHOLD_MAX;
        }

        //Increase powerup drop threshold
        this.POWERUP_SCORE_THRESHOLD += this.POWERUP_SCORE_INCREMENT;
        if(this.POWERUP_SCORE_THRESHOLD > this.POWERUP_SCORE_MAX) {
            this.POWERUP_SCORE_THRESHOLD = this.POWERUP_SCORE_MAX;
        }

        //Increase speed multiplier
        this.SPEED_MULTIPLIER += this.SPEED_MULTIPLIER_INCREMENT;
        if(this.SPEED_MULTIPLIER > this.SPEED_MULTIPLIER_MAX) {
            this.SPEED_MULTIPLIER = this.SPEED_MULTIPLIER_MAX;
        }

        //Increase spawn rate multiplier
        this.SPAWN_MULTIPLIER += this.SPAWN_MULTIPLIER_INCREMENT;
        if(this.SPAWN_MULTIPLIER > this.SPAWN_MULTIPLIER_MAX) {
            this.SPAWN_MULTIPLIER = this.SPAWN_MULTIPLIER_MAX;
        }
    }

    static reset() {
        this.SCORE_THRESHOLD = this.DEFAULT_SCORE_THRESHOLD;
        this.POWERUP_SCORE_THRESHOLD = this.DEFAULT_POWERUP_SCORE_THRESHOLD;
        this.SPEED_MULTIPLIER = this.DEFAULT_SPEED_MULTIPLIER;
        this.SPAWN_MULTIPLIER = this.DEFAULT_SPAWN_MULTIPLIER;
        this.DIFFICULTY_STAGE = 1;
    }
}
