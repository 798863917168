import Phaser, { Cameras } from "phaser";
import InfiniteBackground from "../components/infinite-background";
import ParticleManager from "../components/particle-manager";
import Player from "../components/player";
import PowerUp from "../components/powerup";
import Constants from "../configs/constants";
import Difficulty from "../configs/difficulty";
import Scaling from "../configs/scaling";
import BaseEnemy from "../components/enemies/base-enemy";
import BaseBullet from "../components/weapons/base-bullet";
import EnemiesController from "../components/controllers/enemiesController";
import BulletController from "../components/controllers/bulletController";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import scoreController from "@/utils/game/ScoreController";
import HUD from "./hud";

export default class Main extends Phaser.Scene {
    hud!: HUD
    isGameOver!: boolean;
    haltTriggered!: boolean;
    scoreDifficulty!: number;
    scorePowerUps!: number;
    startTime!: number;
    background!: InfiniteBackground;
    particleManager!: ParticleManager;
    player!: Player;
    powerUpColliderGroup!: Phaser.Physics.Arcade.Group;
    spawnTimer!: Phaser.Time.TimerEvent;
    aliveTimer!: Phaser.Time.TimerEvent;
    enemiesController!: EnemiesController
    bulletController!: BulletController
    powerupSoundLoop!: Phaser.Sound.BaseSound
    hasBoughtLoyalty!: boolean


    constructor() {
        super({
            key: "game"
        });
    }
    
    init() {
        sessionSaveFile.create({
            type: ScoreGameTypes.INFINITE,
            stats: ['killedEnemies']
        });

        this.scene.launch("hud")
    }

    //Main functions
    create() {
        this.isGameOver = false;
        this.haltTriggered = false;
        this.hasBoughtLoyalty = false;
        this.scoreDifficulty = 0;
        this.scorePowerUps = 0;
        this.startTime = Date.now();
        this.hud = this.scene.get("hud") as HUD

        //Send start event
        this.game.events.emit("start");

        //Create static things
        this.background = new InfiniteBackground(this);
        this.particleManager = new ParticleManager(this);

        //Setup player
        this.player = new Player(this);
        this.player.on("pre-death", () => this.hasBoughtLoyalty ? this.gameCleanup() : this.gameHalt());
        this.player.on("death", () => this.gameReset());
        this.player.on("shoot", () => this.bulletController.spawn(Constants.CATEGORY_PLAYER, this.player));
        this.player.on("health", (health: number) => this.hud.events.emit('hud-update-lives', health));
        this.player.on("SetSpawnStatus", (state: boolean) => {
            this.spawnTimer.paused = state
        })

        this.enemiesController = new EnemiesController(this)
        this.bulletController = new BulletController(this)

        //Setup colliders
        this.powerUpColliderGroup = this.physics.add.group();

        this.physics.add.collider(this.player, this.enemiesController, this.enemyCollisionHandler as ArcadePhysicsCallback);
        this.physics.add.collider(this.enemiesController, this.bulletController, this.weaponCollisionHandler as ArcadePhysicsCallback);
        this.physics.add.collider(this.player, this.bulletController, this.weaponCollisionHandler as ArcadePhysicsCallback);
        this.physics.add.collider(this.powerUpColliderGroup, this.player, this.powerUpCollisionHandler as ArcadePhysicsCallback, undefined, this);

        //Setup timers
        this.spawnTimer = this.time.addEvent({
            delay: Constants.ENEMY_SPAWN_INTERVAL,
            loop: true,
            callback: () => this.spawnEnemy()
        });
        this.aliveTimer = this.time.addEvent({
            delay: Constants.SCORE_ALIVE_INTERVAL,
            loop: true,
            callback: () => this.hud.events.emit("hud-update-score", {
                type: "alive",
                amount: Constants.SCORE_ALIVE_BONUS
            })
        });

        const powerupSpawnTimer = this.time.addEvent({

            delay: Constants.POWERUP_COOLDOWN * Difficulty.DIFFICULTY_STAGE,
            loop: true,
            callback: () => {
                //Check for powerups
                let powerUp = this.powerUpColliderGroup.getFirstDead() as PowerUp
                if (powerUp === null || powerUp === undefined) {
                    powerUp = new PowerUp(this);
                    this.powerUpColliderGroup.add(powerUp)
                } else {
                    powerUp.alive()
                }
                
            }
        })

        //Listen to pre-destroy
        this.game.events.on("pre-destroy", () => {
            this.player.death();
            this.gameCleanup();
        });


        this.powerupSoundLoop = this.sound.add("effect-powerup-drop", {
            volume: 0.7,
            loop: true,
        });
        this.game.events.on("setPowerupSound", (state: boolean) => {
            if (state) {
                this.powerupSoundLoop.play()
            } else {
                this.powerupSoundLoop.pause()
            }
        })
    }

    update(time: number, delta: number) {
        this.background.update();

        //Updates
        this.player.update();
        this.enemiesController.children.iterate((enemy) => {
            enemy.update()
        })
        this.powerUpColliderGroup.children.iterate((powerup) => {
            powerup.update()
        })
        this.bulletController.children.iterate((bullet) => {
            bullet.update()
        })

    }


    //Enemy stuff
    spawnEnemy() {
        const enemy: BaseEnemy = this.enemiesController.spawn()

        //Add shoot listener, and save
        enemy.on("death", (points: number, target: BaseEnemy) => {

            enemy.off("death")
            if (!this.isGameOver) {
                this.hud.events.emit("hud-update-score", {
                    type: target.enemyType,
                    amount: points
                });

                this.processScore(target)

            }
        });
        enemy.on("shoot", (speed: number) => {
            const beam = this.bulletController.spawn(Constants.CATEGORY_ENEMY, enemy)
        });
    }

    //Colliders
    enemyCollisionHandler(player: Player, collidedEnemy: BaseEnemy) {
        if (!collidedEnemy.active) {
            return
        }

        player.hit();

        //If enemy is not a rock, destroy it
        if (collidedEnemy.enemyType !== "heavy") {
            collidedEnemy.death(false);
        }
    }

    weaponCollisionHandler(target: Player | BaseEnemy, beam: BaseBullet) {

        if (beam.category !== target.category && target.active && beam.active) {
            target.hit();
            beam.death(!target.active);
        }
    }

    powerUpCollisionHandler(player: Player, powerUp: PowerUp) {

        if (powerUp.active) {
            player.applyPowerUp(powerUp.powerType, this.enemiesController.children.entries as BaseEnemy[]);
            powerUp.death();
        }
    }

    //Misc.
    // type: any because i cant find the event catcher
    processScore(type: any) {
        const score = parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.TOTALSCORE)}`)
        const scoreDifficultyDelta = score - this.scoreDifficulty;
        this.game.events.emit("score", type, score);

        this.scorePowerUps = score;
        //Boost difficulty
        if (scoreDifficultyDelta >= Difficulty.SCORE_THRESHOLD) {
            Difficulty.incrementDifficult();
            // update speed on difficulty change
            this.game.events.emit('speedUp', 0)

            this.spawnTimer.reset({
                delay: Math.round(Constants.ENEMY_SPAWN_INTERVAL / Difficulty.SPAWN_MULTIPLIER),
                loop: true,
                callback: () => this.spawnEnemy()
            });
            this.player.resetFireRate();
            this.scoreDifficulty = score;
        }
    }

    updateComponent(component: BaseBullet | BaseEnemy | PowerUp) {
        component.update();

        //Clean-up item
        if (!component.active || component.isOutOfBounds) {
            component.death(true);
            return false;
        }
        return true;
    }


    //Game state management
    gameHalt() {
        // pause scene
        if (this.scene.isPaused()) {
            return;
        }
        this.scene.pause();
        
        this.game.events.emit("halt", async (restore: any) => {
            this.scene.resume();
            // has bought some lives
            if (restore > 0) {
                this.hasBoughtLoyalty = true;
                // restore health the player has bought
                // this function also update the UI
                this.player.setHealth(restore)
                this.player.applyPowerUp(Constants.POWERUP_SHIELD)

            } else {
                // player has bought nothing end the game
                this.gameCleanup()
            }
        })
    }

    gameCleanup() {
        this.isGameOver = true;
        this.player.death();

        //Clean dynamic elements
        this.enemiesController.children.iterate(enemy => {
            const temp = enemy as BaseEnemy
            temp.death(true)
        });
        this.bulletController.children.iterate((bullet) => {
            const temp = bullet as BaseBullet
            temp.death(true)
        })
        this.powerUpColliderGroup.children.iterate(powerup => {
            const temp = powerup as PowerUp
            temp.death(true)
        })

        //Reset difficulty
        Difficulty.reset();
    }

    gameReset() {
        this.time.removeAllEvents();
        
        //Stop music
        this.sound.removeAllListeners();
        this.sound.stopAll();

        //stop hud
        this.hud.removeAllListeners()
        this.hud.shutdown();
        
        //Stop scene
        this.scene.stop();

        sessionSaveFile.updateValue('highscore', parseInt(`${sessionSaveFile.getValue('totalScore')}`));
        const result = scoreController.getResult();
        this.game.events.emit("end", result);
    }
}