import { parseHexColor } from "@/game/shared/utils/Helpers";
import Setup from "@/helpers/Setup";
import { ScoreDataDefault } from "@/interfaces/ISessionFile";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser from "phaser";
import HUDBox from "../../shared/components/HUDBox";
import UI_Image from "../components/hud/ui_image";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import IScoreType from "../interface/IScoreType";

export default class HUD extends Phaser.Scene {
  isDebugmode!: boolean;
  fpsTracker!: Phaser.GameObjects.Text;
  healthBox!: HUDBox | UI_Image
  scoreBox!: HUDBox
  isusingImage!: boolean

  constructor() {
    super({ key: "hud" });
  }
  init() {
    
  }

  create() {
    // score
    this.scoreBox = new HUDBox(this, Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(64), {
        iconTexture: 'icon_score',
        minWidth: Scaling.getPixelbyDPR(60),
        value: `${Constants.HEALTH_PLAYER}`,
        color: {
            iconBox: parseHexColor(Setup.getValue("settings.game.hudbox.primarycolor")).color,
            textBox: parseHexColor(Setup.getValue("settings.game.hudbox.secondaryColor")).color,
            text: parseHexColor(Setup.getValue("settings.game.hudbox.textColor")).colorString
        },
        origin: new Phaser.Math.Vector2(0, 0)
    })

    // health
    this.isusingImage = Setup.getValue('settings.game.useImageAsHealthUI', false);
    if(this.isusingImage) {
        this.healthBox = new UI_Image(this, this.cameras.main.width - Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(20), 'background-lifes', 1)
    } else {
        this.healthBox = new HUDBox(this, this.cameras.main.width - Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(64), {
            iconTexture: 'icon_lives',
            minWidth: Scaling.getPixelbyDPR(32),
            value: `${Constants.HEALTH_PLAYER}`,
            color: {
                iconBox: parseHexColor(Setup.getValue("settings.game.hudbox.primarycolor")).color,
                textBox: parseHexColor(Setup.getValue("settings.game.hudbox.secondaryColor")).color,
                text: parseHexColor(Setup.getValue("settings.game.hudbox.textColor")).colorString
            },
            origin: new Phaser.Math.Vector2(1, 0)
        })
    }

    // events
    this.events.on('hud-update-lives', (health: number) => {
        this.healthBox.updateText(health.toString())
    })

    this.events.on('hud-update-score', (scoreType: IScoreType) => {
        sessionSaveFile.incrementValue(ScoreDataDefault.TOTALSCORE, scoreType.amount)
        this.scoreBox.updateText(`${sessionSaveFile.getValue(ScoreDataDefault.TOTALSCORE)}`)   
    })
  }

  removeAllListeners() {
    this.events.off('hud-update-lives')
    this.events.off('hud-update-score')
  }

  shutdown() {
    this.scene.stop();
  }
}
