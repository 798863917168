import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import UI_Box from "./ui_box";

export default class UI_Image extends UI_Box {
    healthPointList!: Phaser.GameObjects.Sprite[];
    healthPointEmptyList!: Phaser.GameObjects.Sprite[];

    constructor(scene: Phaser.Scene, x: number, y: number, backgroundKey: string, xOrigin: number) {
        super(scene, x, y, backgroundKey, xOrigin)

        const uiItems = this.createImage();

        this.add(uiItems)
    }

    createImage() {
        // IMAGE SETUP
        this.healthPointList = [];
        this.healthPointEmptyList = []
        let allGameobjects: Phaser.GameObjects.GameObject[] = []

        //Draw stars
        for (let i = 0; i < Constants.HEALTH_PLAYER; i++) {
            const healthPoint = new Phaser.GameObjects.Sprite(this.scene, 0, 0, "icon_lives").setOrigin(1, 0.5);
            const healthPointEmpty = new Phaser.GameObjects.Sprite(this.scene, 0, 0, "health-empty").setOrigin(1, 0.5);

            //Calculate & set positions
            const positionX = i === 0 ? -Scaling.HUD_DEFAULT_MARGIN : -Scaling.HUD_DEFAULT_MARGIN - (healthPoint.width + Scaling.HUD_HEALTH_MARGIN) * i;
            const positionY = this.uiBackground.getCenter().y;
            healthPoint.setPosition(positionX, positionY);
            healthPointEmpty.setPosition(positionX, positionY);

            //Disable empty variants
            healthPointEmpty.setVisible(false);

            //Store items
            this.healthPointList.push(healthPoint);
            this.healthPointEmptyList.push(healthPointEmpty);
            allGameobjects.push(healthPoint, healthPointEmpty)
        }

        return allGameobjects;
    }
    
    // wrapper for hud so it doesnt have to cast to hudbox
    updateText(newtext: string) {
        this.updateValue(parseInt(newtext));
    }

    updateValue(value: number) {
        for (let i = 0; i < Constants.HEALTH_PLAYER; i++) {
            this.healthPointList[i].setVisible(i < value);
            this.healthPointEmptyList[i].setVisible(i >= value);
        }
    }
}