import Phaser from "phaser";
import Snowflakes from "../components/controllers/snowflakes";

export default class Background extends Phaser.Scene {
    background!: Phaser.GameObjects.Image;
    overlay!: Phaser.GameObjects.Image;
    background_audio!: Phaser.Sound.BaseSound;

    constructor() {
        super({
            key: "background"
        });
    }

    create() {
        this.background = this.add.image(this.cameras.main.centerX, 0, 'background').setOrigin(0.5, 0);
        this.background.setDisplaySize(this.cameras.main.width, this.cameras.main.height)

        // Notify that game has booted
        this.game.events.emit("booted");
    }

    createOverlay(): string {
        if (!this.textures.exists('overlay')) {
            const textureBackground = this.make.graphics({ x: 0, y: 0, add: false });
            textureBackground.fillStyle(0x000000, 1);
            textureBackground.fillRect(0, 0, this.cameras.main.width, this.cameras.main.height);
            textureBackground.generateTexture('overlay', this.cameras.main.width, this.cameras.main.height);
        }

        return 'overlay';
    }

    setOverlayAlpha(alpha: number) {
        this.tweens.add({
            targets: this.overlay,
            alpha: alpha,
            duration: 800,
            ease: 'Power.1'
        })
    }

    setColor(color: string | number | Phaser.Types.Display.InputColorObject) {
        this.cameras.main.setBackgroundColor(color ? color : 0x000000);
    }

    playAudio() {
        if (!this.background_audio || !this.background_audio.isPlaying) {
          this.background_audio = this.sound.add('theme', { loop: true, volume: 0 });
          this.background_audio.play();
          this.tweens.add({
            targets: this.background_audio,
            volume: 0.3,
            duration: 1500
          })
        }
      }

    stopMusic() {
        if (this.background_audio) {
            this.background_audio.destroy();
        }
    }
}